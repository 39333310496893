@color_1: #fff;
@color_2: #b7c0cd;
@color_3: #475364;
@color_4: #5b6779;
@font_family_1: Helvetica Neue, sans-serif, Helvetica, Arial;
@background_color_1: #313d4f;
@background_color_2: transparent;

.wrapper {
	position: relative;
	.content-wrapper {
		margin-left: 250px;
		padding-bottom: 20px;
	}
	.left-sidebar {
		position: absolute;
		width: 250px;
		min-height: 1200px !important;
		top: 0;
		bottom: 0;
		display: block;
		background: #273142;
		border-widt: 0;
		box-shadow: inset -1px 0 0 0 #313d4f;
		padding-bottom: 30px;
		.left-sidebar-brand {
			background: #97471a;
			height: 70px;
			width: 100%;
			text-decoration: none;
			display: block;
			margin-bottom: 30px;
			color: @color_1;
			font-size: 24px;
			text-transform: uppercase;
			line-height: 64px;
			text-align: center;
		}
		.left-sidebar-menu {
			padding-left: 0;
			li {
				list-style: none;
				padding-left: 30px;
				padding-right: 30px;
				a {
					font-family: @font_family_1;
					font-weight: 500;
					letter-spacing: .3px;
					font-size: 14px;
					color: @color_2;
					display: block;
					text-transform: capitalize;
					line-height: 36px;
					text-decoration: none;
					.badge {
						float: right;
						margin-top: 6px;
					}
					.menu-icon {
						float: right !important;
						color: @color_3;
						font-size: 14px;
						line-height: 32px;
					}
				}
				&:hover {
					background-color: @background_color_1;
					>a {
						color: @color_1;
					}
				}
				&:hover.has-submenu {
					background-color: @background_color_2;
				}
			}
			li.active {
				background-color: @background_color_1;
				>a {
					color: @color_1;
				}
			}
			li.active.has-submenu {
				background-color: @background_color_2;
			}
			li.menu-header {
				&:hover {
					background-color: @background_color_2;
				}
				a {
					font-family: @font_family_1;
					display: inline-block;
					color: @color_4;
					text-transform: uppercase;
					font-size: 12px;
					cursor: default;
				}
				a.menu-icon {
					float: right !important;
					color: @color_3;
					font-size: 16px;
					cursor: pointer;
					&:hover {
						color: @color_1;
					}
				}
			}
		}
		.seperator {
			background: #354052;
			height: 1px;
			margin-top: 16px;
			margin-bottom: 26px;
		}
		li.has-submenu {
			>ul {
				display: none;
			}
			&:hover {
				background: transparent;
			}
			ul {
				padding-left: 0;
				>li {
					padding-left: 10px;
					>a {
						font-size: 12px;
					}
				}
			}
		}
		li.has-submenu.active {
			>ul {
				display: block;
			}
		}
	}
}
@media (max-width:1024px) {
	.wrapper {
		.content-wrapper {
			margin-left: 0;
			padding-bottom: 20px;
		}
		.left-sidebar {
			position: absolute;
			width: 250px;
			margin-left: -250px;
			left: -250px;
		}
	}
}

.lb-loader {
  text-align: center;
  line-height: 0;
  position: absolute;
  left: 0;
  top: 43%;
  height: 25%;
  width: 100%;
}
.lightbox {
  text-align: center;
  line-height: 0;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  font-weight: 400;
}
.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
}
.lightbox a img {
  border: none;
}
body:after {
  content: url(../images/close.png) url(../images/loading.gif) url(../images/prev.png) url(../images/next.png);
  display: none;
}
.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.lb-dataContainer:after {
  content: "";
  clear: both;
  display: table;
}
.lb-outerContainer {
  position: relative;
  background-color: #fff;
  width: 250px;
  height: 250px;
  margin: 0 auto;
}
.lb-outerContainer:after {
  content: "";
  clear: both;
  display: table;
}
body.lb-disable-scrolling {
  overflow: hidden;
}
.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #000;
  filter: alpha(opacity=80);
  opacity: .8;
  display: none;
}
.lb-nav {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}
.lb-nav a {
  outline: 0;
  background-image: url(data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==);
}
.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: alpha(opacity=0);
  opacity: 0;
  transition: opacity .6s;
}
.lb-nav a.lb-prev:hover {
  filter: alpha(opacity=100);
  opacity: 1;
}
.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  filter: alpha(opacity=0);
  opacity: 0;
  transition: opacity .6s;
}
.lb-nav a.lb-next:hover {
  filter: alpha(opacity=100);
  opacity: 1;
}
.lb-container {
  padding: 4px;
}
.lb-container > .nav {
  left: 0;
}
.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat;
}
.lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}
.lb-prev {
  height: 100%;
  cursor: pointer;
  display: block;
}
.lb-data {
  padding: 0 4px;
  color: #ccc;
}
.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}
.lb-data .lb-caption {
  font-size: 13px;
  font-weight: 700;
  line-height: 1em;
}
.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999;
}
.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) 100% 0 no-repeat;
  text-align: right;
  outline: 0;
  filter: alpha(opacity=70);
  opacity: .7;
  transition: opacity .2s;
}
.lb-data .lb-close:hover {
  cursor: pointer;
  filter: alpha(opacity=100);
  opacity: 1;
}

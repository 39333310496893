.img-icon {
  width: 45px;
  height: 45px;
}
.list-group-navigation .list-group-item {
  border: 1px solid #e6eaee;
}
.list-group-navigation .list-group-item .list-icon {
  width: 40px;
  height: 40px;
  background: #b84c0e;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  float: left;
}
.list-group-navigation .list-group-item .list-text {
  margin-left: 60px;
  color: #354052;
  text-transform: capitalize;
}
.list-group-navigation .list-group-item .list-text .text-muted {
  font-weight: 400;
}
.list-group-navigation .list-group-item:hover {
  background-color: #f2f4f7;
}

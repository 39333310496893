@color_1: #fff;
@color_2: #354052;
@background_color_1: #f2f4f7;

.img-icon {
	width: 45px;
	height: 45px;
}
.list-group-navigation {
	.list-group-item {
		border: 1px solid #e6eaee;
		.list-icon {
			width: 40px;
			height: 40px;
			background: #b84c0e;
			font-size: 16px;
			color: @color_1;
			text-align: center;
			line-height: 40px;
			float: left;
		}
		.list-text {
			margin-left: 60px;
			color: @color_2;
			text-transform: capitalize;
			.text-muted {
				font-weight: 400;
			}
		}
		&:hover {
			background-color: @background_color_1;
		}
	}
}

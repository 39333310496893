@color_1: #ccc;
@color_2: #999;
@background_color_1: #000;
@background_color_2: #fff;

.lb-loader {
	text-align: center;
	line-height: 0;
	position: absolute;
	left: 0;
	top: 43%;
	height: 25%;
	width: 100%;
}
.lightbox {
	text-align: center;
	line-height: 0;
	position: absolute;
	left: 0;
	width: 100%;
	z-index: 10000;
	font-weight: 400;
	.lb-image {
		display: block;
		height: auto;
		max-width: inherit;
	}
	a {
		img {
			border: none;
		}
	}
}
body {
	&:after {
		content: url(../images/close.png) url(../images/loading.gif) url(../images/prev.png) url(../images/next.png);
		display: none;
	}
}
.lb-dataContainer {
	&:after {
		content: "";
		clear: both;
		display: table;
	}
	margin: 0 auto;
	padding-top: 5px;
	width: 100%;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
.lb-outerContainer {
	&:after {
		content: "";
		clear: both;
		display: table;
	}
	position: relative;
	background-color: @background_color_2;
	width: 250px;
	height: 250px;
	margin: 0 auto;
}
body.lb-disable-scrolling {
	overflow: hidden;
}
.lightboxOverlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	background-color: @background_color_1;
	filter: alpha(opacity=80);
	opacity: .8;
	display: none;
}
.lb-nav {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 10;
	a {
		outline: 0;
		background-image: url(data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==);
	}
	a.lb-prev {
		width: 34%;
		left: 0;
		float: left;
		background: url(../images/prev.png) left 48% no-repeat;
		filter: alpha(opacity=0);
		opacity: 0;
		transition: opacity .6s;
		&:hover {
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
	a.lb-next {
		width: 64%;
		right: 0;
		float: right;
		background: url(../images/next.png) right 48% no-repeat;
		filter: alpha(opacity=0);
		opacity: 0;
		transition: opacity .6s;
		&:hover {
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
}
.lb-container {
	padding: 4px;
	>.nav {
		left: 0;
	}
}
.lb-cancel {
	display: block;
	width: 32px;
	height: 32px;
	margin: 0 auto;
	background: url(../images/loading.gif) no-repeat;
}
.lb-next {
	height: 100%;
	cursor: pointer;
	display: block;
}
.lb-prev {
	height: 100%;
	cursor: pointer;
	display: block;
}
.lb-data {
	padding: 0 4px;
	color: @color_1;
	.lb-details {
		width: 85%;
		float: left;
		text-align: left;
		line-height: 1.1em;
	}
	.lb-caption {
		font-size: 13px;
		font-weight: 700;
		line-height: 1em;
	}
	.lb-number {
		display: block;
		clear: left;
		padding-bottom: 1em;
		font-size: 12px;
		color: @color_2;
	}
	.lb-close {
		display: block;
		float: right;
		width: 30px;
		height: 30px;
		background: url(../images/close.png) 100% 0 no-repeat;
		text-align: right;
		outline: 0;
		filter: alpha(opacity=70);
		opacity: .7;
		transition: opacity .2s;
		&:hover {
			cursor: pointer;
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
}

.wrapper {
  position: relative;
}
.wrapper .content-wrapper {
  margin-left: 250px;
  padding-bottom: 20px;
}
.wrapper .left-sidebar {
  position: absolute;
  width: 250px;
  min-height: 1200px !important;
  top: 0;
  bottom: 0;
  display: block;
  background: #273142;
  border-widt: 0;
  box-shadow: inset -1px 0 0 0 #313d4f;
  padding-bottom: 30px;
}
.wrapper .left-sidebar .left-sidebar-brand {
  background: #97471a;
  height: 70px;
  width: 100%;
  text-decoration: none;
  display: block;
  margin-bottom: 30px;
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 64px;
  text-align: center;
}
.wrapper .left-sidebar .left-sidebar-menu {
  padding-left: 0;
}
.wrapper .left-sidebar .left-sidebar-menu li {
  list-style: none;
  padding-left: 30px;
  padding-right: 30px;
}
.wrapper .left-sidebar .left-sidebar-menu li a {
  font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
  font-weight: 500;
  letter-spacing: .3px;
  font-size: 14px;
  color: #b7c0cd;
  display: block;
  text-transform: capitalize;
  line-height: 36px;
  text-decoration: none;
}
.wrapper .left-sidebar .left-sidebar-menu li a .badge {
  float: right;
  margin-top: 6px;
}
.wrapper .left-sidebar .left-sidebar-menu li a .menu-icon {
  float: right !important;
  color: #475364;
  font-size: 14px;
  line-height: 32px;
}
.wrapper .left-sidebar .left-sidebar-menu li:hover {
  background-color: #313d4f;
}
.wrapper .left-sidebar .left-sidebar-menu li:hover > a {
  color: #fff;
}
.wrapper .left-sidebar .left-sidebar-menu li:hover.has-submenu {
  background-color: transparent;
}
.wrapper .left-sidebar .left-sidebar-menu li.active {
  background-color: #313d4f;
}
.wrapper .left-sidebar .left-sidebar-menu li.active > a {
  color: #fff;
}
.wrapper .left-sidebar .left-sidebar-menu li.active.has-submenu {
  background-color: transparent;
}
.wrapper .left-sidebar .left-sidebar-menu li.menu-header:hover {
  background-color: transparent;
}
.wrapper .left-sidebar .left-sidebar-menu li.menu-header a {
  font-family: Helvetica Neue, sans-serif, Helvetica, Arial;
  display: inline-block;
  color: #5b6779;
  text-transform: uppercase;
  font-size: 12px;
  cursor: default;
}
.wrapper .left-sidebar .left-sidebar-menu li.menu-header a.menu-icon {
  float: right !important;
  color: #475364;
  font-size: 16px;
  cursor: pointer;
}
.wrapper .left-sidebar .left-sidebar-menu li.menu-header a.menu-icon:hover {
  color: #fff;
}
.wrapper .left-sidebar .seperator {
  background: #354052;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 26px;
}
.wrapper .left-sidebar li.has-submenu > ul {
  display: none;
}
.wrapper .left-sidebar li.has-submenu:hover {
  background: transparent;
}
.wrapper .left-sidebar li.has-submenu ul {
  padding-left: 0;
}
.wrapper .left-sidebar li.has-submenu ul > li {
  padding-left: 10px;
}
.wrapper .left-sidebar li.has-submenu ul > li > a {
  font-size: 12px;
}
.wrapper .left-sidebar li.has-submenu.active > ul {
  display: block;
}
@media (max-width: 1024px) {
  .wrapper .content-wrapper {
    margin-left: 0;
    padding-bottom: 20px;
  }
  .wrapper .left-sidebar {
    position: absolute;
    width: 250px;
    margin-left: -250px;
    left: -250px;
  }
}
